import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { dateFnsLocales } from '@engined/core/helpers/date.js';
import { Box } from '@mui/material';
import { formatRelative } from 'date-fns';
import React from 'react';

interface OwnProps {
  date: string | Date;
  /**
   * dateTime attribute of <time> element
   */
  dateTime?: string | null;
}

type Props = OwnProps;

const FormatRelativeDate: React.FunctionComponent<Props> = ({ date, dateTime = null }) => {
  const { language } = useLocale();

  return (
    <Box component="time" suppressHydrationWarning dateTime={dateTime}>
      {formatRelative(new Date(date), new Date(), {
        locale: dateFnsLocales[language],
      })}
    </Box>
  );
};

FormatRelativeDate.displayName = 'FormatRelativeDate';

export default FormatRelativeDate;
