import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { To } from 'history';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface OwnProps {
  icon?: React.ReactNode;
  label: React.ReactNode;
  to: To;
  selected: boolean;
  level?: '1' | '2';
  onClick(event: React.MouseEvent<HTMLAnchorElement>);
}

type Props = OwnProps;

const MenuItem: React.FunctionComponent<Props> = ({ icon, to, label, selected, onClick, level = '1' }) => {
  return (
    <ListItem
      sx={{ pl: level === '2' ? 8 : undefined, color: 'inherit' }}
      component={RouterLink}
      to={to}
      onClick={onClick}
      disablePadding
    >
      <ListItemButton selected={selected}>
        <ListItemIcon sx={{ color: 'inherit', minWidth: '32px' }}>{icon}</ListItemIcon>
        <ListItemText
          primary={label}
          sx={{ color: selected ? 'common.white' : undefined }}
          primaryTypographyProps={{ fontWeight: 700 }}
        />
      </ListItemButton>
    </ListItem>
  );
};

MenuItem.displayName = 'MenuItem';

export default MenuItem;
