import { ActiveTimeEntry_Load } from '@asaprint/asap/components/ActiveTimeEntry.graphql';
import ActiveTimeEntry from '@asaprint/asap/components/ActiveTimeEntry.js';
import CollapsibleMenuItem from '@asaprint/asap/components/CollapsibleMenuItem.js';
import DrawerContainer from '@asaprint/asap/components/DrawerContainer.js';
import DrawerFooter from '@asaprint/asap/components/DrawerFooter.js';
import DrawerMenu from '@asaprint/asap/components/DrawerMenu.js';
import DrawerToolbar from '@asaprint/asap/components/DrawerToolbar.js';
import HasPermission from '@asaprint/asap/components/HasPermission.js';
import Layout from '@asaprint/asap/components/layouts/Layout.js';
import MenuItem from '@asaprint/asap/components/MenuItem.js';
import { Notifications_LoadNotificationsCount } from '@asaprint/asap/components/Notifications.graphql';
import Notifications from '@asaprint/asap/components/Notifications.js';
import SynchronizeButton from '@asaprint/asap/components/SynchronizeButton.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import { SEO_APP_LABEL } from '@asaprint/asap/locales/client.js';
import {
  ADMIN_EXPORTS_ROUTE,
  ADMIN_PHASE_GROUPS_ROUTE,
  ADMIN_PHASES_ROUTE,
  ADMIN_ROUTE,
  ADMIN_SETTINGS_ROUTE,
  ADMIN_USERS_ROUTE,
  AUTH_LOGIN_ROUTE,
  DASHBOARD_ROUTE,
  DAY_VIEW_ROUTE,
  ORDERS_RECEIVED_ROUTE,
  ORDERS_RECEIVED_SHOW_ROUTE,
  PLANNING_ASSEMBLY_ROUTE,
  PLANNING_GRAPHIC_DESIGN_ROUTE,
  PLANNING_PRODUCTION_ROUTE,
  PRODUCTS_ROUTE,
  PROFILE_ROUTE,
  TIME_ENTRIES_ORDER_RECEIVED_ROUTE,
  TIME_ENTRIES_PRODUCT_CREATIVE_ROUTE,
  TIME_ENTRIES_PRODUCT_ROUTE,
  TIME_ENTRIES_ROUTE,
} from '@asaprint/asap/routes.js';
import { Permission } from '@asaprint/common/access.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import FontAwesomeSvgIcon from '@engined/client/components/FontAwesomeSvgIcon.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { url } from '@engined/core/services/routes.js';
import { faWikipediaW } from '@fortawesome/free-brands-svg-icons';
import {
  CalendarMonth as CalendarMonthIcon,
  Dashboard as DashboardIcon,
  Event as EventIcon,
  PendingActions as PendingActionsIcon,
  RequestQuote as RequestQuoteIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { Box, ListItemIcon, ListItemText, MenuItem as MUIMenuItem } from '@mui/material';
import { upperFirst } from 'lodash-es';
import React, { useCallback } from 'react';
import { Link as RouterLink, matchPath, Navigate, Outlet, redirect, useLocation } from 'react-router-dom';

interface OwnProps {}

type Props = OwnProps;

const appbarSx = { mr: -1 };

const AuthLayout: React.FunctionComponent<Props> = () => {
  const location = useLocation();
  const { requestUser } = useAuth();

  const renderDrawer = useCallback(
    ({ drawerClose }) => {
      return (
        <DrawerContainer>
          <DrawerToolbar skin="1">
            <Box display="flex" alignItems="center">
              <Box component="img" src={`${requestUser.avatar}&size=144`} borderRadius="50%" width={48} height={48} />
              <Box flexGrow={1} ml={2}>
                <Box>{displayName(requestUser)}</Box>
                <Box color="#ddd">{upperFirst(requestUser.role)}</Box>
              </Box>
            </Box>
          </DrawerToolbar>
          <DrawerMenuContent pathname={location.pathname} drawerClose={drawerClose} />
          <DrawerFooter />
        </DrawerContainer>
      );
    },
    [requestUser, location.pathname],
  );

  const renderUserMenu = useCallback(
    ({ menuClose }) => (
      <MUIMenuItem onClick={menuClose} component={RouterLink} to={PROFILE_ROUTE}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText>Profil</ListItemText>
      </MUIMenuItem>
    ),
    [],
  );

  if (!requestUser) {
    return <Navigate to={AUTH_LOGIN_ROUTE} replace />;
  }

  return (
    <Layout
      renderDrawer={renderDrawer}
      renderUserMenu={renderUserMenu}
      skin="1"
      toolbar={
        <Box display="flex" alignItems="center">
          <ActiveTimeEntry sx={{ display: { xs: 'none', md: 'block' } }} />
          <SynchronizeButton sx={appbarSx} />
          <Notifications sx={appbarSx} />
        </Box>
      }
    >
      <Box>
        <ActiveTimeEntry sx={{ display: { xs: 'block', md: 'none' }, my: 2 }} />
        <Outlet />
      </Box>
    </Layout>
  );
};

export default AuthLayout;

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    if (!req.user) {
      return redirect(url(AUTH_LOGIN_ROUTE, {}, { next: req.url }));
    }

    const result = await Promise.all([
      apollo.query({ query: Notifications_LoadNotificationsCount }),
      apollo.query({ query: ActiveTimeEntry_Load }),
    ]);
    return result.map((r) => r.data);
  }

  return null;
};

export const handle = {
  meta: ({ locale: { t } }: MetaFunctionArgs) => ({
    title: `${t(SEO_APP_LABEL)}`,
  }),
};

let DrawerMenuContent: React.FC<{ pathname: string; drawerClose() }> = ({ pathname, drawerClose }) => {
  return (
    <DrawerMenu>
      <MenuItem
        label="Dashboard"
        icon={<DashboardIcon />}
        to={DASHBOARD_ROUTE}
        selected={!!matchPath(DASHBOARD_ROUTE, pathname)}
        onClick={drawerClose}
      />

      <CollapsibleMenuItem
        icon={<RequestQuoteIcon />}
        label="Objednávky"
        open={true}
        selected={
          !!matchPath(ORDERS_RECEIVED_ROUTE, pathname) ||
          !!matchPath(PRODUCTS_ROUTE, pathname) ||
          !!matchPath(ORDERS_RECEIVED_SHOW_ROUTE, pathname)
        }
      >
        <MenuItem
          label="Objednávky prijaté"
          selected={!!matchPath(ORDERS_RECEIVED_ROUTE, pathname) || !!matchPath(ORDERS_RECEIVED_SHOW_ROUTE, pathname)}
          to={ORDERS_RECEIVED_ROUTE}
          onClick={drawerClose}
        />
        <MenuItem
          label="Produkty"
          selected={!!matchPath(PRODUCTS_ROUTE, pathname)}
          to={PRODUCTS_ROUTE}
          onClick={drawerClose}
        />
      </CollapsibleMenuItem>

      <CollapsibleMenuItem
        icon={<CalendarMonthIcon />}
        label="Plánovanie"
        open={true}
        selected={
          !!matchPath(PLANNING_GRAPHIC_DESIGN_ROUTE, pathname) ||
          !!matchPath(PLANNING_PRODUCTION_ROUTE, pathname) ||
          !!matchPath(PLANNING_ASSEMBLY_ROUTE, pathname)
        }
      >
        <MenuItem
          label="Plán graf. štúdia"
          selected={!!matchPath(PLANNING_GRAPHIC_DESIGN_ROUTE, pathname)}
          to={PLANNING_GRAPHIC_DESIGN_ROUTE}
          onClick={drawerClose}
        />
        <MenuItem
          label="Plán výroby"
          selected={!!matchPath(PLANNING_PRODUCTION_ROUTE, pathname)}
          to={PLANNING_PRODUCTION_ROUTE}
          onClick={drawerClose}
        />
        <MenuItem
          label="Plán montáže"
          selected={!!matchPath(PLANNING_ASSEMBLY_ROUTE, pathname)}
          to={PLANNING_ASSEMBLY_ROUTE}
          onClick={drawerClose}
        />
      </CollapsibleMenuItem>

      <MenuItem
        label="Denný timesheet"
        icon={<EventIcon />}
        to={DAY_VIEW_ROUTE}
        selected={!!matchPath(DAY_VIEW_ROUTE, pathname)}
        onClick={drawerClose}
      />

      <MenuItem
        label="Pracovný log"
        icon={<PendingActionsIcon />}
        to={TIME_ENTRIES_ROUTE}
        selected={
          !!matchPath(TIME_ENTRIES_ROUTE, pathname) ||
          !!matchPath(TIME_ENTRIES_ORDER_RECEIVED_ROUTE, pathname) ||
          !!matchPath(TIME_ENTRIES_PRODUCT_ROUTE, pathname) ||
          !!matchPath(TIME_ENTRIES_PRODUCT_CREATIVE_ROUTE, pathname)
        }
        onClick={drawerClose}
      />

      <MenuItem
        label="Wiki"
        icon={<FontAwesomeSvgIcon icon={faWikipediaW} />}
        to="https://wiki.asaprint.sk/"
        selected={false}
        onClick={drawerClose}
      />

      <HasPermission
        permission={[
          Permission.UsersManage,
          Permission.PhaseGroupsManage,
          Permission.PhasesManage,
          Permission.SettingsRead,
          Permission.ExportsRead,
        ]}
      >
        <CollapsibleMenuItem
          icon={<SettingsIcon />}
          label="Administrácia"
          selected={
            !!matchPath(ADMIN_ROUTE, pathname) ||
            !!matchPath(ADMIN_SETTINGS_ROUTE, pathname) ||
            !!matchPath(ADMIN_PHASE_GROUPS_ROUTE, pathname) ||
            !!matchPath(ADMIN_PHASES_ROUTE, pathname) ||
            !!matchPath(ADMIN_EXPORTS_ROUTE, pathname) ||
            !!matchPath(ADMIN_USERS_ROUTE, pathname)
          }
        >
          <HasPermission permission={Permission.SettingsRead}>
            <MenuItem
              label="Nastavenia"
              selected={!!matchPath(ADMIN_SETTINGS_ROUTE, pathname)}
              to={ADMIN_SETTINGS_ROUTE}
              onClick={drawerClose}
            />
          </HasPermission>
          <HasPermission permission={Permission.UsersManage}>
            <MenuItem
              label="Používatelia"
              selected={!!matchPath(ADMIN_USERS_ROUTE, pathname)}
              to={ADMIN_USERS_ROUTE}
              onClick={drawerClose}
            />
          </HasPermission>
          <HasPermission permission={Permission.PhaseGroupsManage}>
            <MenuItem
              label="Fázy produktu - skupiny"
              selected={!!matchPath(ADMIN_PHASE_GROUPS_ROUTE, pathname)}
              to={ADMIN_PHASE_GROUPS_ROUTE}
              onClick={drawerClose}
            />
          </HasPermission>
          <HasPermission permission={Permission.PhasesManage}>
            <MenuItem
              label="Fázy produktu"
              selected={!!matchPath(ADMIN_PHASES_ROUTE, pathname)}
              to={ADMIN_PHASES_ROUTE}
              onClick={drawerClose}
            />
          </HasPermission>
          <HasPermission permission={Permission.ExportsRead}>
            <MenuItem
              label="Export"
              selected={!!matchPath(ADMIN_EXPORTS_ROUTE, pathname)}
              to={ADMIN_EXPORTS_ROUTE}
              onClick={drawerClose}
            />
          </HasPermission>
        </CollapsibleMenuItem>
      </HasPermission>
    </DrawerMenu>
  );
};
DrawerMenuContent = React.memo(DrawerMenuContent);
