import { useMutation, useSubscription } from '@apollo/client/index.js';
import {
  SynchronizeButtonJobFinished,
  SynchronizeButtonSynchronize,
} from '@asaprint/asap/components/SynchronizeButton.graphql';
import {
  SynchronizeButtonJobFinishedSubscription,
  SynchronizeButtonJobFinishedSubscriptionVariables,
  SynchronizeButtonSynchronizeMutation,
  SynchronizeButtonSynchronizeMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import FontAwesomeSvgIcon from '@engined/client/components/FontAwesomeSvgIcon.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import { SxProps } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface OwnProps {
  sx?: SxProps;
}

type Props = OwnProps;

const SynchronizeButton: React.FunctionComponent<Props> = ({ sx }) => {
  const [jobId, setJobId] = useState<string>(uuidv4());
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [synchronizeExecute, { data: queryData }] = useMutation<
    SynchronizeButtonSynchronizeMutation,
    SynchronizeButtonSynchronizeMutationVariables
  >(SynchronizeButtonSynchronize);

  const { data: subscribedData } = useSubscription<
    SynchronizeButtonJobFinishedSubscription,
    SynchronizeButtonJobFinishedSubscriptionVariables
  >(SynchronizeButtonJobFinished, { variables: { id: jobId } });

  const onClick = useEventCallback(async () => {
    try {
      setLoading(true);
      const response = await synchronizeExecute({ variables: { id: jobId } });
      if (response.data.synchronize.state === 'completed') {
        setLoading(false);
        setJobId(uuidv4());
      }
    } catch (err) {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (jobId === subscribedData?.jobFinished?.id) {
      setLoading(false);
      setJobId(uuidv4());
      if (subscribedData?.jobFinished?.state === 'failed') {
        enqueueSnackbar('Nepodarilo sa dokončiť synchronizáciu.', { variant: 'error' });
      }
    }
  }, [subscribedData, jobId, enqueueSnackbar]);

  return (
    <IconButton color="inherit" edge="end" size="large" onClick={onClick} sx={sx}>
      <FontAwesomeSvgIcon icon={faSync} fontSize="small" spin={loading} />
    </IconButton>
  );
};

SynchronizeButton.displayName = 'SynchronizeButton';

export default React.memo<Props>(SynchronizeButton);
