import { styled } from '@mui/material';

const DrawerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}));

DrawerContainer.displayName = 'DrawerContainer';

export default DrawerContainer;
