import useIntervalState from '@engined/client/hooks/useIntervalState.js';
import { calculateInterval } from '@engined/core/helpers/date.js';
import React from 'react';

interface OwnProps {
  startAt: string;
  displaySeconds?: boolean;
}

type Props = OwnProps;

const DurationTimer: React.FunctionComponent<Props> = ({ startAt, displaySeconds = false }) => {
  const duration = useIntervalState<Duration>(displaySeconds ? 1 : 30, (prevState) =>
    calculateInterval(new Date(), startAt),
  );
  const hours = duration.hours + duration.days * 24;
  return (
    <>
      {hours.toString().padStart(2, '0')}:{duration.minutes.toString().padStart(2, '0')}
      {displaySeconds ? <>:{duration.seconds.toString().padStart(2, '0')}</> : null}
    </>
  );
};

DurationTimer.displayName = 'DurationTimer';

export default DurationTimer;
