import { useMutation, useSubscription } from '@apollo/client/index.js';
import Notification from '@asaprint/asap/components/Notification.js';
import {
  Notifications_LoadNotifications,
  Notifications_LoadNotificationsCount,
  Notifications_NotificationCreated,
  Notifications_ReadAll,
  Notifications_Seen,
  Notifications_Subscribe,
} from '@asaprint/asap/components/Notifications.graphql';
import useUserSettings from '@asaprint/asap/hooks/useUserSettings.js';
import {
  Notifications_LoadNotificationsCountQuery,
  Notifications_LoadNotificationsCountQueryVariables,
  Notifications_LoadNotificationsQuery,
  Notifications_LoadNotificationsQueryVariables,
  Notifications_NotificationCreatedSubscription,
  Notifications_NotificationCreatedSubscriptionVariables,
  Notifications_NotificationFragment,
  Notifications_ReadAllMutation,
  Notifications_ReadAllMutationVariables,
  Notifications_SeenMutation,
  Notifications_SeenMutationVariables,
  Notifications_SubscribeMutation,
  Notifications_SubscribeMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { generateSubscription } from '@asaprint/asap/services/webpush.client.js';
import Loading from '@engined/client/components/Loading.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import usePopover, { bindPopover } from '@engined/client/hooks/usePopover.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import { scrollbar } from '@engined/client/styles/mixins.js';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';
import { PopoverActions } from '@mui/material/Popover/Popover.js';
import { SxProps } from '@mui/system';
import React, { RefObject, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

interface OwnProps {
  sx?: SxProps;
}

type Props = OwnProps;

const Notifications: React.FunctionComponent<Props> = ({ sx }) => {
  const {
    data: countsData,
    subscribeToMore: unseenSubscribeToMore,
    refetch,
  } = useQuery<Notifications_LoadNotificationsCountQuery, Notifications_LoadNotificationsCountQueryVariables>(
    Notifications_LoadNotificationsCount,
    {
      pollInterval: 30 * 1000,
    },
  );

  const popoverState = usePopover(false, 'notifications');
  const popoverActions = useRef<PopoverActions>();

  const [seenExecute] = useMutation<Notifications_SeenMutation, Notifications_SeenMutationVariables>(
    Notifications_Seen,
    {
      refetchQueries: [{ query: Notifications_LoadNotificationsCount }],
    },
  );
  const [subscribeExecute] = useMutation<Notifications_SubscribeMutation, Notifications_SubscribeMutationVariables>(
    Notifications_Subscribe,
  );

  const onToggle = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if (!popoverState.open) {
      seenExecute();
    }
    popoverState.onOpen(event);
  });

  // Temporary hack for apollo/client SSR unsubscribe bug
  useSubscription<
    Notifications_NotificationCreatedSubscription,
    Notifications_NotificationCreatedSubscriptionVariables
  >(Notifications_NotificationCreated, {
    onData() {
      refetch();
    },
  });

  useEffect(() => {
    const execute = async () => {
      if (!('Notification' in window)) {
        // Notification not supported - ignore
        return;
      }

      const registration = await navigator.serviceWorker.getRegistration();
      if (registration) {
        if (window.Notification.permission === 'default') {
          await window.Notification.requestPermission();
        }
        const subscription = await generateSubscription(registration);
        if (subscription) {
          const json = subscription.toJSON();
          await subscribeExecute({
            variables: {
              input: {
                ...json,
                expirationTime: json.expirationTime ? new Date(json.expirationTime).toISOString() : null,
              },
            },
          });
        }
      }
    };

    execute();
  }, [subscribeExecute]);

  const unseenCount = countsData?.unseenNotifications?.pageInfo.count;
  const unreadCount = countsData?.unreadNotifications?.pageInfo.count;

  return (
    <>
      <IconButton color="inherit" edge="end" size="large" onClick={onToggle} sx={sx}>
        <Badge
          badgeContent={unseenCount > 0 ? unseenCount : unreadCount > 0 ? unreadCount : 0}
          color={unseenCount > 0 ? 'primary' : 'neutral'}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        {...bindPopover(popoverState)}
        action={popoverActions}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotificationsContent popoverActions={popoverActions} />
      </Popover>
    </>
  );
};

Notifications.displayName = 'Notifications';

export default React.memo<Props>(Notifications);

interface NotificationsContentOwnProps {
  popoverActions: RefObject<PopoverActions>;
}

const NotificationsContent: React.FunctionComponent<NotificationsContentOwnProps> = ({ popoverActions }) => {
  const { data, loading, error, subscribeToMore } = useQuery<
    Notifications_LoadNotificationsQuery,
    Notifications_LoadNotificationsQueryVariables
  >(Notifications_LoadNotifications);

  const [readAllExecute, { loading: readAllLoading }] = useMutation<
    Notifications_ReadAllMutation,
    Notifications_ReadAllMutationVariables
  >(Notifications_ReadAll, {
    refetchQueries: [{ query: Notifications_LoadNotificationsCount }],
  });

  const onReadAllClick = useEventCallback(() => {
    readAllExecute();
  });

  // Subscribe to new notifications entities
  useEffect(() => {
    return subscribeToMore<
      Notifications_NotificationCreatedSubscription,
      Notifications_NotificationCreatedSubscriptionVariables
    >({
      document: Notifications_NotificationCreated,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data) {
          return prev;
        }

        return {
          ...prev,
          notifications: {
            ...prev.notifications,
            edges: [
              {
                __typename: 'NotificationEdge',
                node: subscriptionData.data.notificationCreated,
              },
              ...prev.notifications.edges,
            ],
          },
        };
      },
    });
  }, [subscribeToMore]);

  const notificationsEdges = data?.notifications?.edges;
  const unreadNotificationsEdges = data?.unreadNotifications?.edges;
  const notifications = useMemo<Notifications_NotificationFragment[]>(() => {
    if (!notificationsEdges && !unreadNotificationsEdges) {
      return [];
    }

    const notifications = notificationsEdges.map((e) => e.node);
    const ids = notifications.map((n) => n.id);
    return [...notifications, ...unreadNotificationsEdges.map((e) => e.node).filter((n) => !ids.includes(n.id))];
  }, [notificationsEdges, unreadNotificationsEdges]);

  const [onlyUnreadSetting, setOnlyUnreadSetting] = useUserSettings('Notifications__onlyUnread', false);
  const [unreadNotifications, setUnreadNotifications] = useState(null);
  const onShowOnlyUnreadClick = useEventCallback(() => {
    setUnreadNotifications((s) => (s ? null : notifications.filter((n) => !n.readAt)));
    setOnlyUnreadSetting((s) => !s);
  });

  const displayedNotifications = onlyUnreadSetting
    ? unreadNotifications ?? notifications.filter((n) => !n.readAt)
    : notifications;

  useLayoutEffect(() => {
    popoverActions.current?.updatePosition();
  }, [data, popoverActions]);

  return (
    <Box
      sx={{
        maxHeight: 'cacl(100vh - 71px)',
        width: { xs: 'calc(100vw - 10px)', md: '620px' },
        minWidth: 0,
        userSelect: 'none',
        p: 2,
        ...scrollbar,
      }}
    >
      {error || (loading && !data) ? (
        <Loading error={error} />
      ) : notifications?.length > 0 ? (
        <>
          <Box textAlign="right">
            <Button onClick={onShowOnlyUnreadClick} sx={{ mr: 2 }}>
              {onlyUnreadSetting ? 'Zobraziť všetky' : 'Zobraziť iba neprečítané'}
            </Button>
            <LoadingButton loading={readAllLoading} onClick={onReadAllClick}>
              Označiť všetko ako prečítané
            </LoadingButton>
          </Box>
        </>
      ) : null}

      <List>
        {displayedNotifications.length > 0 ? (
          displayedNotifications.map((notification, index) => (
            <React.Fragment key={notification.id}>
              <ListItem>
                <ListItemButton>
                  <Notification notification={notification} />
                </ListItemButton>
              </ListItem>
              {index + 1 < displayedNotifications.length && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <ListItem>
            <ListItemText sx={{ textAlign: 'center' }}>
              {notifications.length && onlyUnreadSetting && displayedNotifications.length === 0
                ? 'Aktuálne nemáte žiadne neprečítané notifikácie'
                : 'Aktuálne nemáte žiadne notifikácie :('}
            </ListItemText>
          </ListItem>
        )}
      </List>
    </Box>
  );
};

NotificationsContent.displayName = 'NotificationsContent';
