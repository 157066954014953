import ConnectedBreadcrumbs from '@asaprint/asap/components/ConnectedBreadcrumbs.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { HEADER_MENU_LOGOUT } from '@asaprint/asap/locales/client.js';
import { AUTH_LOGIN_ROUTE } from '@asaprint/asap/routes.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { Resource } from '@engined/core/services/i18n.js';
import { AccountCircle, Logout as LogoutIcon, Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  drawerClasses,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem as MUIMenuItem,
  styled,
  SwipeableDrawer,
  Toolbar,
} from '@mui/material';
import { To } from 'history';
import React, { useState } from 'react';
import { Link as RouterLink, useMatches } from 'react-router-dom';

const drawerWidth = 220;
const menuId = 'account-menu';

export type Breadcrumb =
  | {
      to?: To | ((params) => To);
      text: Resource | string;
    }
  | {
      node: React.ReactNode;
    };

const Root = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'skin',
})<{ skin: OwnProps['skin'] }>(({ theme, skin }) => ({
  minHeight: '100vh',
  display: 'flex',
  backgroundColor: '#f4f6fa',
  [`& .${drawerClasses['paper']}`]: {
    width: drawerWidth,
    backgroundColor: skin === '4' ? '#5b0302' : '#2f4050',
    color: skin === '4' ? '#948b96' : '#a7b1c2',
  },
}));

Root.displayName = 'Root';

const SkinedSwipeableDrawer = styled(SwipeableDrawer, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'skin',
})<{ skin: OwnProps['skin'] }>(({ theme, skin }) => ({
  [`& .${drawerClasses['paper']}`]: {
    width: drawerWidth,
    backgroundColor: skin === '4' ? '#5b0302' : '#2f4050',
    color: skin === '4' ? '#948b96' : '#a7b1c2',
  },
}));

SkinedSwipeableDrawer.displayName = 'SkinedSwipeableDrawer';

const ToolbarTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  marginRight: theme.spacing(8),
}));

ToolbarTitle.displayName = 'ToolbarTitle';

const Username = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  textTransform: 'none',
}));

Username.displayName = 'Username';

const Nav = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

Nav.displayName = 'Nav';

const ToolbarOffset = styled('div')(({ theme }) => ({ minHeight: 48 }));

ToolbarOffset.displayName = 'ToolbarOffset';

const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  flexGrow: 1,
  maxWidth: `calc(100vw - ${drawerWidth}px)`,
  // overflowX: 'hidden',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100vw',
  },
}));

Main.displayName = 'Main';

const MainContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const matches = useMatches();
  const maxWidth = (matches?.at(-1).handle as RouteHandle)?.maxWidth ?? false;
  return (
    <Container sx={{ flexGrow: 1, p: 2 }} maxWidth={maxWidth}>
      {children}
    </Container>
  );
};
MainContent.displayName = 'MainContent';

interface OwnProps {
  renderDrawer: ({ drawerClose }: { drawerClose: () => void }) => React.ReactNode;
  renderUserMenu: ({ menuClose }: { menuClose: () => void }) => React.ReactNode;
  skin: '1' | '4';
  toolbar?: React.ReactNode;
  children: React.ReactNode;
}

type Props = OwnProps;

const Layout: React.FunctionComponent<Props> = ({ children, renderDrawer, renderUserMenu, skin, toolbar }) => {
  const { t } = useLocale();
  const { requestUser, logout } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleProfileMenuOpen = useEventCallback((event) => {
    setAnchorEl(event.currentTarget);
  });
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = useEventCallback(() => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  });

  const [menuOpen, setMenuOpen] = useState(false);
  const onDrawerToggle = useEventCallback(() => {
    setMenuOpen((s) => !s);
  });

  const onDrawerClose = useEventCallback(() => {
    setMenuOpen(false);
  });

  const onLogout = useEventCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setAnchorEl(null);
    logout();
  });

  const container = typeof window !== 'undefined' ? window.document.body : undefined;

  const drawer = renderDrawer({ drawerClose: onDrawerClose });

  return (
    <Root skin={skin}>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: '#f3f3f4',
          pl: 2,
          pr: 2,
        }}
        color="default"
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            sx={{ mr: 2, display: { md: 'none' } }}
            onClick={onDrawerToggle}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <ToolbarTitle>
            <Box display={{ xs: 'none', md: 'block' }}>
              <ConnectedBreadcrumbs />
            </Box>
          </ToolbarTitle>
          {toolbar && <Box sx={{ mr: 1 }}>{toolbar}</Box>}
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Button onClick={handleProfileMenuOpen} color="inherit">
              <AccountCircle />
              <Username>{displayName(requestUser)}</Username>
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton onClick={handleProfileMenuOpen} color="inherit" edge="end" size="large">
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {renderUserMenu({ menuClose: handleMenuClose })}
        <Divider />
        <MUIMenuItem component={RouterLink} to={AUTH_LOGIN_ROUTE} onClick={onLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>{t(HEADER_MENU_LOGOUT)}</ListItemText>
        </MUIMenuItem>
      </Menu>
      <Nav aria-label="Hlavné menu">
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <SkinedSwipeableDrawer
            container={container}
            variant="temporary"
            open={menuOpen}
            onClose={onDrawerToggle}
            onOpen={onDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            skin={skin}
          >
            {drawer}
          </SkinedSwipeableDrawer>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Drawer variant="permanent" open>
            {drawer}
          </Drawer>
        </Box>
      </Nav>

      <Main>
        <ToolbarOffset />
        <MainContent>{children}</MainContent>
        {/*<Footer />*/}
      </Main>
    </Root>
  );
};

Layout.displayName = 'Layout';

export default Layout;
