import { scrollbar } from '@engined/client/styles/mixins.js';
import { List, styled } from '@mui/material';

const DrawerMenu = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  ...scrollbar,
}));

DrawerMenu.displayName = 'DrawerMenu';

export default DrawerMenu;
