import { useMutation } from '@apollo/client/index.js';
import { Notification_Read } from '@asaprint/asap/components/Notification.graphql';
import { ORDERS_RECEIVED_SHOW_ROUTE } from '@asaprint/asap/routes.js';
import {
  Notification_ReadMutation,
  Notification_ReadMutationVariables,
  Notifications_NotificationFragment,
} from '@asaprint/asap/schema.client.types.js';
import { ORDER_RECEIVED_PHASE_NAMES } from '@asaprint/common/constants/OrderReceived.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import FormatRelativeDate from '@engined/client/components/FormatRelativeDate.js';
import Link from '@engined/client/components/Link.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { url } from '@engined/core/services/routes.js';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { To } from 'history';
import React from 'react';

interface OwnProps {
  className?: string;
  notification: Notifications_NotificationFragment;
}

type Props = OwnProps;

const Notification: React.FunctionComponent<Props> = ({ className, notification }) => {
  const [readExecute] = useMutation<Notification_ReadMutation, Notification_ReadMutationVariables>(Notification_Read, {
    variables: { ids: [notification.id] },
    refetchQueries: ['Notifications_LoadNotificationsCount'],
  });

  const onClick = useEventCallback(() => {
    readExecute();
  });

  if (notification.__typename === 'OrderReceivedPhaseChangedNotification') {
    return (
      <NotificationLayout
        icon={faCommentsDollar}
        className={className}
        to={url(ORDERS_RECEIVED_SHOW_ROUTE, { id: notification.orderReceived.id })}
        onClick={onClick}
        createdAt={notification.createdAt}
        readAt={notification.readAt}
      >
        Fáza objednávky{' '}
        <strong>
          {notification.orderReceived.moneyAddressName} - {notification.orderReceived.moneyName}
        </strong>{' '}
        bola zmenená na <strong>{ORDER_RECEIVED_PHASE_NAMES[notification.toPhase]}</strong>.
      </NotificationLayout>
    );
  } else if (notification.__typename === 'ProductCommentCreatedNotification') {
    return (
      <NotificationLayout
        icon={faCommentsDollar}
        className={className}
        to={
          url(ORDERS_RECEIVED_SHOW_ROUTE, { id: notification.productComment.product.orderReceived.id }) +
          `#product-${notification.productComment.product.id}`
        }
        onClick={onClick}
        createdAt={notification.createdAt}
        readAt={notification.readAt}
      >
        <strong>{displayName(notification.productComment.createdBy)}</strong> komentoval grafický návrh{' '}
        v&nbsp;objednávke{' '}
        <strong>
          {notification.productComment.product.orderReceived.moneyAddressName} -{' '}
          {notification.productComment.product.orderReceived.moneyName}
        </strong>
        .
      </NotificationLayout>
    );
  } else if (notification.__typename === 'ProductAssignedNotification') {
    return (
      <NotificationLayout
        icon={faCommentsDollar}
        className={className}
        to={
          url(ORDERS_RECEIVED_SHOW_ROUTE, { id: notification.product.orderReceived.id }) +
          `#product-${notification.product.id}`
        }
        onClick={onClick}
        createdAt={notification.createdAt}
        readAt={notification.readAt}
      >
        <strong>{displayName(notification.assignedBy)}</strong> ti priradil produkt{' '}
        <strong>{notification.product.name}</strong> v&nbsp;objednávke{' '}
        <strong>
          {notification.product.orderReceived.moneyAddressName} - {notification.product.orderReceived.moneyName}
        </strong>
        .
      </NotificationLayout>
    );
  } else if (notification.__typename === 'OrderReceivedExpeditionAssignedToNotification') {
    return (
      <NotificationLayout
        icon={faCommentsDollar}
        className={className}
        to={url(ORDERS_RECEIVED_SHOW_ROUTE, { id: notification.orderReceived.id })}
        onClick={onClick}
        createdAt={notification.createdAt}
        readAt={notification.readAt}
      >
        <strong>{displayName(notification.assignedBy)}</strong> ťa priradil ako zodpovednú osobu za expedíciu{' '}
        v&nbsp;objednávke{' '}
        <strong>
          {notification.orderReceived.moneyAddressName} - {notification.orderReceived.moneyName}
        </strong>
        .
      </NotificationLayout>
    );
  } else if (notification.__typename === 'OrderReceivedMissingPackagingAssignedToNotification') {
    return (
      <NotificationLayout
        icon={faCommentsDollar}
        className={className}
        to={url(ORDERS_RECEIVED_SHOW_ROUTE, { id: notification.orderReceived.id })}
        onClick={onClick}
        createdAt={notification.createdAt}
        readAt={notification.readAt}
      >
        <strong>
          Objednávka {notification.orderReceived.moneyAddressName} - {notification.orderReceived.moneyName}
        </strong>{' '}
        je vo fáze <strong>{ORDER_RECEIVED_PHASE_NAMES[notification.orderReceived.phase]}</strong>, ale nemá nastavenú
        zodpovednú osobu za balenie..
      </NotificationLayout>
    );
  } else if (notification.__typename === 'OrderReceivedPackagingAssignedToNotification') {
    return (
      <NotificationLayout
        icon={faCommentsDollar}
        className={className}
        to={url(ORDERS_RECEIVED_SHOW_ROUTE, { id: notification.orderReceived.id })}
        onClick={onClick}
        createdAt={notification.createdAt}
        readAt={notification.readAt}
      >
        <strong>{displayName(notification.assignedBy)}</strong> ťa priradil ako zodpovednú osobu za balenie{' '}
        v&nbsp;objednávke{' '}
        <strong>
          {notification.orderReceived.moneyAddressName} - {notification.orderReceived.moneyName}
        </strong>
        .
      </NotificationLayout>
    );
  } else {
    return null;
  }
};

Notification.displayName = 'Notification';

export default React.memo<Props>(Notification);

interface NotificationLayoutOwnProps {
  className?: string;
  icon: IconProp;
  createdAt: string;
  readAt: string | null;
  to: To;
  children: React.ReactNode;

  onClick();
}

const NotificationLayout: React.FunctionComponent<NotificationLayoutOwnProps> = ({
  icon,
  createdAt,
  readAt,
  to,
  onClick,
  children,
}) => (
  <Link to={to} onClick={onClick} underline="none" sx={{ width: '100%' }}>
    <Box display="flex" alignItems="center" color="text.primary">
      <Box fontSize="1.5rem" mr={2}>
        <FontAwesomeIcon icon={icon} fixedWidth />
      </Box>
      <Box flexGrow={1} lineHeight="1.5em">
        {children}
        <Box fontSize="0.7em">
          <FormatRelativeDate date={createdAt} />
        </Box>
      </Box>
      {!readAt && (
        <Box ml={1} alignSelf="flex-start">
          <Box bgcolor="primary.main" width={8} height={8} borderRadius="50%" />
        </Box>
      )}
    </Box>
  </Link>
);

NotificationLayout.displayName = 'NotificationLayout';
