import { styled } from '@mui/material';
import headerPatternSkin1 from '@asaprint/asap/images/patterns/header-profile-skin-1.png';
import headerPatternSkin3 from '@asaprint/asap/images/patterns/header-profile-skin-3.png';

interface DrawerToolbarProps {
  skin: '1' | '3';
}

const headerPatterns = {
  1: headerPatternSkin1,
  3: headerPatternSkin3,
};

const DrawerToolbar = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'skin',
})<DrawerToolbarProps>(({ theme, skin }) => ({
  ...theme.mixins.toolbar,
  backgroundImage: `url(${headerPatterns[skin]})`,
  backgroundRepeat: 'no-repeat',
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: theme.spacing(4),
  fontWeight: 'bold',
}));

DrawerToolbar.displayName = 'DrawerToolbar';

export default DrawerToolbar;
