import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react';

interface OwnProps {
  icon?: React.ReactNode;
  label: React.ReactNode;
  selected: boolean;
  open?: boolean;
  children: React.ReactNode;
}

type Props = OwnProps;

const CollapsibleMenuItem: React.FunctionComponent<Props> = ({ icon, label, selected, open = false, children }) => {
  const [localOpen, setOpen] = useState(selected || open);
  const onToggle = useEventCallback(() => {
    setOpen((s) => !s);
  });

  return (
    <>
      <ListItem onClick={onToggle} disablePadding>
        <ListItemButton selected={selected}>
          <ListItemIcon sx={{ color: 'inherit', minWidth: '32px' }}>{icon}</ListItemIcon>
          <ListItemText
            primary={label}
            sx={{ color: selected ? 'common.white' : 'inherit' }}
            primaryTypographyProps={{ fontWeight: 700 }}
          />
          {localOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={localOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

CollapsibleMenuItem.displayName = 'CollapsibleMenuItem';

export default CollapsibleMenuItem;
