import { useSubscription } from '@apollo/client/index.js';
import {
  ActiveTimeEntry_Created,
  ActiveTimeEntry_Load,
  ActiveTimeEntry_Updated,
} from '@asaprint/asap/components/ActiveTimeEntry.graphql';
import DurationTimer from '@asaprint/asap/components/DurationTimer.js';
import { DAY_VIEW_ROUTE, ORDERS_RECEIVED_SHOW_ROUTE } from '@asaprint/asap/routes.js';
import {
  ActiveTimeEntry_CreatedSubscription,
  ActiveTimeEntry_CreatedSubscriptionVariables,
  ActiveTimeEntry_LoadQuery,
  ActiveTimeEntry_LoadQueryVariables,
  ActiveTimeEntry_UpdatedSubscription,
  ActiveTimeEntry_UpdatedSubscriptionVariables,
} from '@asaprint/asap/schema.client.types.js';
import { cyan } from '@asaprint/asap/theme.js';
import { progressBarStripes } from '@asaprint/common/helpers/animations.js';
import Loading from '@engined/client/components/Loading.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import { url } from '@engined/core/services/routes.js';
import { Badge, Box, Chip, chipClasses, SxProps } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  sx?: SxProps;
}

const ActiveTimeEntry: React.FC<Props> = ({ sx }) => {
  const { data, loading, error, refetch } = useQuery<ActiveTimeEntry_LoadQuery, ActiveTimeEntry_LoadQueryVariables>(
    ActiveTimeEntry_Load,
  );

  useSubscription<ActiveTimeEntry_CreatedSubscription, ActiveTimeEntry_CreatedSubscriptionVariables>(
    ActiveTimeEntry_Created,
    {
      onData() {
        refetch();
      },
    },
  );
  useSubscription<ActiveTimeEntry_UpdatedSubscription, ActiveTimeEntry_UpdatedSubscriptionVariables>(
    ActiveTimeEntry_Updated,
    {
      onData() {
        refetch();
      },
    },
  );

  if (error || (loading && !data)) {
    return <Loading error={error} size="18px" />;
  }

  const entries = data.myActiveTimeEntries;

  if (!entries.length) {
    // No active entries
    return null;
  }

  const first = entries[0];

  let label = '';
  let to = '';
  if (first.product) {
    // For product
    label = `${first.orderReceived.moneyNumber}-${first.product.position} - ${first.orderReceived.moneyAddressName} - ${first.product.name}`;
    to = url(ORDERS_RECEIVED_SHOW_ROUTE, { id: first.orderReceived.id }) + `#product-${first.product.id}`;
  } else if (first.orderReceived) {
    label = `${first.orderReceived.moneyNumber} - ${first.orderReceived.moneyAddressName} - ${first.orderReceived.moneyName}`;
    to = url(ORDERS_RECEIVED_SHOW_ROUTE, { id: first.orderReceived.id });
  } else {
    label = `${first.note}`;
    to = DAY_VIEW_ROUTE;
  }

  return (
    <Box mr={2} sx={sx}>
      <Badge
        badgeContent={entries.length > 1 ? entries.length : 0}
        color="primary"
        sx={{ width: { xs: '100%', md: 'auto' } }}
      >
        <Chip
          component={RouterLink}
          to={to}
          label={
            <>
              <Box component="span" textOverflow="ellipsis" overflow="hidden" minWidth={0} whiteSpace="nowrap">
                {label}
              </Box>
              <Box component="strong">
                <DurationTimer key={first.startAt} startAt={first.startAt} />
              </Box>
            </>
          }
          sx={{
            width: { xs: '100%', md: '400px' },
            justifyContent: 'flex-start',
            color: 'common.white',
            backgroundColor: cyan,
            backgroundImage:
              'linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)',
            backgroundSize: '1rem 1rem',
            animation: `${progressBarStripes} 1s linear infinite`,
            [`& .${chipClasses.label}`]: {
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            },
          }}
        />
      </Badge>
    </Box>
  );
};

export default ActiveTimeEntry;
