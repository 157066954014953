import { useEffect, useRef, useState } from 'react';

type ShouldUpdate<TState> = (state?: TState | undefined) => TState;

const defaultUpdater: ShouldUpdate<number> = (s) => (s ?? 0) + 1;

export default function useIntervalState<TState = number>(
  seconds: number,
  shouldUpdate?: ShouldUpdate<TState> | undefined,
): TState {
  const shouldUpdateRef = useRef<ShouldUpdate<TState | number>>(shouldUpdate ?? defaultUpdater);
  const [state, setState] = useState(shouldUpdateRef.current());
  const prevState = useRef(state);

  useEffect(() => {
    shouldUpdateRef.current = shouldUpdate ?? defaultUpdater;
  }, [shouldUpdate]);

  useEffect(() => {
    const timer = window.setInterval(() => {
      const next = shouldUpdateRef.current(prevState.current);
      if (next !== prevState.current) {
        prevState.current = next;
        setState(next);
      }
    }, seconds * 1000);

    return () => {
      window.clearTimeout(timer);
    };
  }, [seconds]);

  return state as TState;
}
