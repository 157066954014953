import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import useMounted from '@engined/client/hooks/useMounted.js';
import { MouseEvent, useState } from 'react';

interface State<T, TElement extends Element = Element> {
  open: boolean;
  anchorEl: TElement;
  value: T;
  // Cached last value -> so that exiting transition do not shrink if conditional
  lastValue: T;
  onClose();
  onOpen(event?: MouseEvent<TElement>, value?: T);
  id;
}

type StateValue<T> = T | boolean | null;

export default function usePopover<T = boolean, TElement extends Element = Element>(
  initialValue: StateValue<T>,
  id: string,
): State<T> {
  const [state, setState] = useState<{
    value: StateValue<T>;
    lastValue: StateValue<T>;
    anchorEl: TElement;
  }>({
    anchorEl: null,
    value: initialValue,
    lastValue: initialValue,
  });

  const mounted = useMounted();

  const onClose = useEventCallback<State<StateValue<T>>['onClose']>(() => {
    if (mounted.current) {
      setState((s) => ({
        ...s,
        anchorEl: null,
        value: null,
      }));
    }
  });

  const onOpen = useEventCallback<State<StateValue<T>>['onOpen']>((event: MouseEvent<TElement>, value: T) => {
    setState({
      anchorEl: event.currentTarget,
      value: value ?? true,
      lastValue: value ?? true,
    });
  });

  return {
    open: !!state.value,
    anchorEl: state.anchorEl,
    value: typeof state.value === 'boolean' ? null : state.value,
    lastValue: typeof state.lastValue === 'boolean' ? null : state.lastValue,
    onClose,
    onOpen,
    id,
  };
}

export function bindPopover<T = boolean, TElement extends Element = Element>(state: State<T, TElement>) {
  return {
    open: state.open,
    anchorEl: state.anchorEl,
    id: state.id,
    onClose: state.onClose,
  };
}

export function bindPopoverTrigger<T = boolean, TElement extends Element = Element>(
  state: State<T, TElement>,
  value?: T,
) {
  return {
    onClick: value ? (event: React.MouseEvent<TElement>) => state.onOpen(event, value) : state.onOpen,
    'aria-describedby': state.id,
  };
}
